<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
          <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100" v-if="!loading">
        <div class="banner" :style="{ 'background-image': `url(${require('../../../../assets/images/common/top_banner_' + theme_color + '.png')})` }">
            <h1>{{ title }}报告</h1>
            <p>
              <span v-if="subtitle">{{ subtitle }}</span>
            </p>
        </div>
        <!-- 网媒 -->
        <div class="fxbg rdwb" v-if="data.hasOwnProperty('net_media')">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">网媒相关热点人物
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">通过实体识别，提取方案相关的<br>Top10热点人物</div>
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350" v-show="data.net_media.persons.length < 1">
                        <div class="img"></div>
                    </div>
                    <div class="h-350" id="persons" v-show="data.net_media.persons.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">网媒热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">网媒热门关键词</div>
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                    </h3>
                    <div class="pad-lr-20 pos-r">
                      <div class="echarts_empty h-350" v-show="expand_data.wm_words.length < 1">
                          <div class="img"></div>
                      </div>
                      <div class="h-350" id="orgs" v-show="expand_data.wm_words.length >= 1"></div>
                    </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12" style="display:none;">
                <el-card class="box-card rdzt">
                  <h3 class="mod-title">网媒热点主题
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">通过主题模型提取的Top10热点<br>主题</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.net_media.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.net_media.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span>话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data.net_media['topics']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else></var>
                            <em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
               <el-col :span="12">
                <el-card class="box-card fardmt">
                  <h3 class="mod-title">网媒热门信息发布地区
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">网媒热门信息发布地区</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div id="hotarea" style="height:394px;"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card fardmt">
                  <h3 class="mod-title">网媒热点媒体
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">根据发布相关信息数量计其热度，<br>呈现热度最高的10个媒体</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.net_media.sites.length < 1">
                        <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-show="data.net_media.sites.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>关注指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data.net_media['sites']" :key="index">
                            <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                            <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data.net_media['sites'][0].value * 100" :show-text="false"></el-progress>
                            <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card rdwz">
              <h3 class="mod-title">网媒热点文章
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">通过主题模型将文章进行分类，并<br>使用密度聚类模型从热门主题中挑<br>选出Top20热门文章</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.net_media.articles.length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list progress_no_bg" v-show="data.net_media.articles.length >= 1">
                  <ul>
                    <li>
                        <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息标题</span><span>信息摘要</span><span class="tag f-r" style="padding-right: 15%;">热点指数 <el-tooltip effect="dark" placement="bottom"><i class="el-icon-info"></i><div slot="content">透过话题分类模型将文章进行分类，并从热门的话题中使用密度聚<br>类模型挑选出热门文章，最后标准化成1-100分的分数，得分越高<br>者代表越热门</div></el-tooltip></span></h3>
                    </li>
                    <li v-for="(row, index) in data.net_media['articles']" :key="index" v-show="index < 10">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a>
                      <p>{{ row.summary }}</p>
                      <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data.net_media['articles'][0].heat * 100" :show-text="false"></el-progress>
                      <span class="tag">{{ row.heat }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <!--@end 网媒 -->
        <!-- 微博 -->
        <div class="fxbg rdwb" v-if="data.hasOwnProperty('weibo')" >
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博相关热点用户
                    <el-tooltip class="item" effect="dark" content="通过实体识别，提取方案相关热点用户" placement="bottom">
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350" v-show="data.weibo.persons.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-350" id="persons_weibo" v-show="data.weibo.persons.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博热点关键词
                    <el-tooltip class="item" effect="dark" content="微博热点关键词" placement="bottom">
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350" v-show="expand_data.wb_words.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-350" id="orgs_weibo" v-show="expand_data.wb_words.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">微博热点用户
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">根据发布与方案相关微博数、转发数、<br>评论数和点赞数计其热度，呈现热度<br>高的10个微博博主</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.weibo.authors.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 ov-h" v-show="data.weibo.authors.length >= 1">
                  <ul class="user_list">
                    <li v-for="(row, index) in data.weibo['authors']" :key="index">
                      <div class="flex">
                        <div class="tx">
                          <img :src="row.avatar" class="img" v-if="row.avatar" onerror="javascript:this.src='/empty_user_avatar.svg';" />
                          <img src="@/assets/images/common/empty_user_avatar.svg" width="40" v-else />
                          <span class="num top1" v-if="index == 0"></span>
                          <span class="num top2" v-if="index == 1"></span>
                          <span class="num top3" v-if="index == 2"></span>
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </div>
                        <div class="con">
                          <p> <a :href="row.home" target="_blank" :title="row.name">{{ row.name }}</a> </p>
                          <p>文章数：<em>{{ row.articles }}</em></p>
                          <p>总转发：<em>{{ row.forward }}</em></p>
                          <p>总评论：<em>{{ row.remark }}</em></p>
                          <p>总点赞：<em>{{ row.zan }}</em></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card rdzt">
                  <h3 class="mod-title">微博热门话题
                    <el-tooltip class="item" effect="dark" content="通过主题模型提取的Top10热门话题" placement="bottom">
                        <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.weibo.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.weibo.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span class="mod-list-title1">话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data.weibo['topics']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else></var><em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj">
                  <h3 class="mod-title">微博相关热门文章
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">根据微博中的URL链接被传播的次数计<br>其热度，呈现热度最高的10条URL链接</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.weibo.urls.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-show="data.weibo.urls.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">URL链接</span><span class="tag rdzs">热点指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data.weibo['urls']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.url" target="_blank">{{ row.url }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data.weibo['urls'][0].heat * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.heat }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20" style="display:none;">
            <el-card class="box-card rdwz">
              <h3 class="mod-title">微博热点文章
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">通过主题模型将文章进行分类，并使用密度聚<br>类模型从热门主题中挑选出Top10热门文章<br></div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.weibo.articles.length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list progress_no_bg" v-show="data.weibo.articles.length >= 1">
                  <ul>
                    <li>
                      <h3><span class="num-tit">序号</span><span class="mod-list-title2">信息摘要</span><span class="tag f-r">热点指数 <el-tooltip effect="dark" placement="bottom"><i class="el-icon-info"></i><div slot="content">透过话题分类模型将文章进行分类，并从热门的话题中使用密度聚<br>类模型挑选出热门文章，最后标准化成1-100分的分数，得分越高<br>者代表越热门</div></el-tooltip></span><span class="tag xxly">信息来源</span></h3>
                    </li>
                    <li v-for="(row, index) in data.weibo['articles']" :key="index" v-show="index < 10">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.summary">{{ row.summary }}</a>
                      <a class="tag xxly f-l">
                        <span class="ico v_red" v-if="row.type == '金V'"></span>
                        <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                        <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                        <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                        <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        <var>{{ row.author }}</var>
                      </a>
                      <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data.weibo['articles'][0].heat * 100" :show-text="false"></el-progress>
                      <span class="tag">{{ row.heat }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <!--@end 微博 -->
        <!-- 微信 -->
        <div class="fxbg" v-if="data.hasOwnProperty('wechat')" style="display:none;">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微信相关热点人物
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">通过实体识别，提取方案相关的<br>Top10热点人物</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350" v-show="data.wechat.persons.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-350" id="persons_wechat" v-show="data.wechat.persons.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微信相关热点企业
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">通过实体识别，提取方案相关的<br>Top10热点企业</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350" v-show="data.wechat.orgs.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-350" id="orgs_wechat" v-show="data.wechat.orgs.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card fardmt">
                  <h3 class="mod-title">微信热点公众号
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">根据发布相关信息数量计其热度，<br>呈现热度最高的10个公众号</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.wechat.authors.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-show="data.wechat.authors.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>关注指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data.wechat['authors']" :key="index">
                          <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data.wechat['authors'][0].value * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card rdzt">
                  <h3 class="mod-title">微信公众号热点主题
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">通过主题模型提取的Top10热点<br>主题</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.wechat.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.wechat.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span>话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data.wechat['topics']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else="index >= 3"></var>
                            <em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card rdwz">
              <h3 class="mod-title">微信公众号热点文章
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">通过主题模型提取的Top10热点文章</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.wechat.articles.length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list progress_no_bg" v-show="data.wechat.articles.length >= 1">
                  <ul>
                    <li>
                      <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息标题</span><span>信息摘要</span><span class="tag f-r">热点指数 <el-tooltip effect="dark" placement="bottom"><i class="el-icon-info"></i><div slot="content">透过话题分类模型将文章进行分类，并从热门的话题中使用密度聚<br>类模型挑选出热门文章，最后标准化成1-100分的分数，得分越高<br>者代表越热门</div></el-tooltip></span></h3>
                    </li>
                    <li v-for="(row, index) in data.wechat['articles']" :key="index" v-if="index < 10">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a>
                      <p>{{ row.summary }}</p>
                      <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data.wechat['articles'][0].heat * 100" :show-text="false"></el-progress>
                      <span class="tag">{{ row.heat }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <!--@end 微信 -->
        <!-- 论坛 -->
        <div class="fxbg">
          <div class=" warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">热门评论
                  </h3>
                  <div class="pad-lr-20 pos-r fardmt">
                    <div class="echarts_empty" v-if="expand_data.pl_hot.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-else>
                      <ul>
                        <li><h3><span class="mod-list-title2">评论内容</span><span>评论指数</span></h3></li>
                        <li v-for="(row, index) in expand_data.pl_hot" :key="index">
                          <a class="mod-list-title2" :title="row.content">{{ row.content }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="(row.count / expand_data.pl_hot[0].count) * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.count }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">评论热门关键词
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty" v-show="expand_data.pl_words.length < 1">
                      <div class="img"></div>
                    </div>
                    <div id="pl_words" v-show="expand_data.pl_words.length >= 1" style="height:394px;"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="el-row-20 mar-t-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">热门评论来源
                  </h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty" v-show="expand_data.pl_media < 1">
                      <div class="img"></div>
                    </div>
                    <div style="height:394px;" id="pl_media" v-show="expand_data.pl_media.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">评论热门文章
                  </h3>
                  <div class="pad-lr-20 pos-r comment_article">
                    <div class="echarts_empty" v-if="expand_data.pl_msg.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-else>
                    <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">文章链接</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(msg, index) in expand_data.pl_msg" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="msg.subject_url" target="_blank">{{ msg.subject_url }}</a>
                          <span class="tag rdzs">{{ msg.source_name_s }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--@end 论坛 -->
    </div>
  </el-container>
</template>
<script type="application/javascript">
  import { detail } from "@/api/analysis";
  import { globalCreateChart } from "@/utils/helpers.js"
  const d3 = require("d3");
  const { _ } = window
  export default {
    name: "analysis-hot",
    data() {
      return {
        title: "",
        data: {},
        loading: true,
        created: "",
        expand_data: {}
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      loadData() {
        detail({id: this.$route.query.id}).then(res => {
          if (res.data.state) {
            let data = res.data.data;
            this.data = data.result;
            this.expand_data = JSON.parse(data.expand_data)
            window.console.log(this.data)
            const { title, updated, subtitle, theme_color } = res.data.data;
            this.title = title;
            this.subtitle = subtitle;
            this.theme_color = theme_color;
            this.created = updated;
            this.loading = false;
            this.$nextTick(() => {
              if (this.data.hasOwnProperty('net_media')) {
                this.popChart(this.data.net_media['persons'], 'persons', 'persons');
                // this.popChart(this.data.net_media['orgs'], 'orgs', 'orgs');
              }
              if (this.data.hasOwnProperty('weibo')) {
                this.popChart(this.data.weibo['persons'], 'persons_weibo', 'persons');
                // this.popChart(this.data.weibo['orgs'], 'orgs_weibo', 'orgs');
              }
              if (this.data.hasOwnProperty('wechat')) {
                this.popChart(this.data.wechat['persons'], 'persons_wechat', 'persons');
                this.popChart(this.data.wechat['orgs'], 'orgs_wechat', 'orgs');
              }

              // 扩展
              this.wordcloud(this.expand_data.wm_words, 'orgs')
              this.hotarea()
              this.wordcloud(this.expand_data.wb_words, 'orgs_weibo')
              this.wordcloud(this.expand_data.pl_words, 'pl_words')
              this.plBar(this.expand_data.pl_media, 'pl_media')
            })
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.
          this.$message.error("加载数据失败，服务失败");
        })
      },
      plBar(data, id) {
        data = _.orderBy(data, ['num', 'desc'])
        let yAxis = []
        let series = []
        const colors = ['#ff9587', '#fcd41a', '#62affe', '#74ccb1', '#76d4ff', '#2a38d8', '#ffb146', '#66cb03', '#0168b7']
        _(data).forEach((item, i) => {
          yAxis.push(item.name)
          series.push({
            value: item.num,
            itemStyle: {
              color: colors[i]
            }
          })
        });

        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: '2%',
            top:"2%",
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: yAxis
          },
          series: [
            {
              type: 'bar',
              barWidth: 10,
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius:[0, 5, 5, 0]
                }
              },
              data: series
            }
          ]
        }

        let element = window.document.getElementById(id);
        if (!element) return false; // 切换时直接break

        const myChart = globalCreateChart(element, option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      },
      // 热门词云
      wordcloud(chartData, id) {
        var maskImage = new Image();
        //重点：云彩图片的base64码
        maskImage.src = require("@/assets/images/common/ciyun.jpg");
        var data = [];
        _(chartData).forEach((item, i) => {
          data.push({name: item["keyword"], value: item["num"]});
        });
        data = data.sort(function (a, b) {
          return b.value - a.value;
        });

        maskImage.onload = function () {
          let option = {
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                name: "信息数",
                type: "wordCloud",
                shape: "circle", // square circle cardioid diamond triangle-forward triangle
                right: "0",
                top: "0%",
                width: "100%",
                height: "100%",
                sizeRange: [14, 50],
                rotationRange: [0, 0],
                rotationStep: 90,
                hape: "pentagon",
                gridSize: 16,
                drawOutOfBound: false,
                textStyle: {
                  normal: {
                    fontFamily: "sans-serif",
                    color: function (params) {
                      const colors = [
                        "#555DFE",
                        "#4E89FE",
                        "#8CB6FF",
                        "#A5A5A5",
                      ];
                      let index = Math.floor(Math.random() * colors.length);
                      return colors[index];
                    },
                  },
                  emphasis: {
                    shadowBlur: 10,
                    shadowColor: "#d2d2d2",
                  },
                },
                data: data,
              },
            ],
          };

          let element = window.document.getElementById(id);
          if (!element) return false; // 切换时直接break

          option.series[0].maskImage = maskImage;
          var myChart = globalCreateChart(element, option);
          window.addEventListener("resize", () => {
            myChart.resize();
          });
        }
      },
      hotarea() {
        var result = this.expand_data.wm_area;
        var data = [];
        _.remove(result, (row) => {
          return row.name === "其他";
        });
        if (_.size(result) < 1) return false;
        result = result.sort((a, b) => {
          return b.value - a.value;
        });
        let max = result[0].value;

        _(result).forEach((item, i) => {
          if (window.provinceGeoCoord.hasOwnProperty(item.name)) {
            data.push({
              name: item.name,
              value: window.provinceGeoCoord[item.name].concat(item.value),
            });
          }
        });
        let option = {
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              return params.data.name + "： " + params.data.value[2] + "条";
            },
          },
          geo: {
            map: "china",
            label: {
              emphasis: {
                show: false,
              },
            },
            roam: false,
            itemStyle: {
              normal: {
                areaColor: "#c1ceff",
                borderColor: "#fff",
              },
              emphasis: {
                areaColor: "#c1ceff",
              },
            },
          },
          series: [
            {
              name: "地域信息数",
              data: data,
              type: "effectScatter",
              coordinateSystem: "geo",
              symbolSize: function (val) {
                return (val[2] / max) * 10 < 3 ? 3 : (val[2] / max) * 15;
              },
              showEffectOn: "render",
              rippleEffect: {
                brushType: "stroke",
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: "#0081e7",
                  shadowBlur: 10,
                  shadowColor: "#333",
                },
              },
              zlevel: 1,
            },
          ],
        };

        this.$nextTick(() => {
          let element = window.document.getElementById("hotarea");
          if (!element) return false; // 切换时直接break
          var myChart = globalCreateChart(element, option);

          window.addEventListener("resize", () => {
            myChart.resize();
          });
        });

      },
      popChart(data, id, type) {
        if (Array.prototype.isPrototypeOf(data) && (data.length < 1)) return false
        let maxr = data.length >= 1 ? data.sort((a, b) => { return b.value - a.value })[0].value : 0
        function randomColor() {
          // Random color
          let color = ['#f55b57', '#8bb0f1', '#838383', '#fe4f4c', '#faa975', '#63ae4a', '#f75e5b']
          let index = Math.floor((Math.random()*color.length))
          return color[index]
        }
        function Bubble(option){
          var _defaultOption = {
            width: 530,
            height: 250,
            padding:1.5,
            data:'',
            conEle:''
          };
          option = $.extend(true, _defaultOption,option);
          this.width  = option.width;
          this.height = option.height;
          this.padding= option.padding;
          this.data   = option.data;//数据url或对象,必填
          this.conEle = option.conEle;//svg容器(node或者选择器)，必填
          this.mouseenter = function(d,node){}
          this.mouseleave = function(d,node){}
          this.click = function (d) {}
        }
        Bubble.prototype.init = function(){
          var that = this,
            //1.设置颜色
            color = d3.scale.category20c(),
            //2.布局
            bubble = d3.layout.pack()
              .sort(null)
              .size([that.width,that.height])
              .radius((d) => {
                let val = d / maxr * 50
                return val < 30 ? 30 : val
              })
              .padding(that.padding),
            //3.添加svg元素
            svg = d3.select(that.conEle).append("svg")
              .attr("width", that.width)
              .attr("font-size", '12')
              .attr("height", that.height);
          //4.数据请求及图形绘制
          if(typeStr(that.data)=='[object string]'){
            d3.json(that.data,function(error,data){
              if(error) throw error;
              //1.对数据进行处理
              data = dataHandle(data);
              render(svg,bubble,that,data);
            })
          }else{
            render(svg,bubble,that,dataHandle(that.data));
          }
        }
        function typeStr(obj){
          return Object.prototype.toString.call(obj).toLowerCase();
        }
        //Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root){
          var classes = [];                                                                                        //存储结果的数组
          /*
           * 自定义递归函数
           * 第二个参数指传入的json对象
           */
          function recurse(name, node) {
            if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            {
              node.children.forEach(function(child) {                                                          //将孩子结点中的每条数据
                recurse(node.name, child);
              })
            }
            else {
              //如果自身是孩子结点的，将内容压入数组
              classes.push({ name: node.name, value: node.size,props:node.props})
            };
          }
          recurse(null, root);
          return {children: classes};
        }
        function render(view,layout,context,data,cb){
          var node = view.selectAll(".node")
            //绑定数据（配置结点）
              .data(layout.nodes(classes(data))
                .filter(function(d) {
                  //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                  return !d.children;
                }))
              .enter().append("g")
              .attr("class", "node")
              .attr("transform", function(d) {
                //设定g移动
                return "translate(" + d.x + "," + d.y + ")";
              }),
            usingNodes = node.filter(function(d){
              return d.props.using;
            }),
            time = +new Date(),
            duration = 1000,
            strokeWidth = 0;
          node.append("circle")
            .attr("r", function(d) {
              //设置圆的半径
              return d.r;
            })
            .style("fill", function(d) {
              //气泡颜色
              return d.props.color;
            })
            .style("fill-opacity",1);
          node.append("text")
            .attr("dy", ".3em")
            //设置文本对齐
            .style("text-anchor", "middle")
            .style("font-size",'10px')
            .style("font-weight",'600')
            .style("fill", function (d) {
              //字体颜色
              return type == 'persons' ? "#5f99ea" : randomColor()
            })
            //根据半径的大小来截取对应长度字符串(很重要)
            .text(function(d) {
              return d.name.substring(0, d.r / 5);
            });
          function animate(){
            var nowTime = +new Date();
            if((nowTime-duration) > time) {
              time = nowTime;
              strokeWidth = 0;
            }
            strokeWidth += 0.6;
            //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
            usingNodes.select("circle")
              .style("stroke-width",strokeWidth+'px')
              .style("stroke-opacity",'0.3')
              .style("stroke",function(d){
                return d.props.color;
              });
            requestAnimationFrame(animate);
          }
          animate();
          node.on('mouseenter',function(d){
            var node = this;
            context.mouseenter(d,node);
          })
          node.on('mouseleave',function(d){
            var node = this;
            context.mouseleave(d,node);
          })
          node.on('click', function (d) {
            var node = this;
            context.click(d)
          })
        }
        //定义数据处理方法
        function dataHandle(data){
          var result = {
            name:"flare",
            children:[]
          }
          data.forEach(function(ele){
            result.children.push({
              name:ele.name,
              size:ele.value,
              props:ele.props
            });
          });
          return result;
        }
        function createInfoTip(d){
          var html = '<div class="node-info fz-12"><ul>';
          html += '<li class="info-title"><span>'+d.name+'</span></li>';
          html += '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数'+
            '</span><span class="info-content-text">'+d.value+'</span></li>';
          html += '</ul></div>';
          return html;
        }
        // 清除旧数据
        window.jQuery("#"+id).children().remove()
        d3.select('#'+id)
          .selectAll('*')
          .remove();
        var chartData = [];
        _(data).forEach((item, i) => {
          chartData.push({
            name: item.name,
            value: item.value,
            props: {
              abnormal: false,
              color: "#f4f4f4",
              using: false
            }
          })
        })
        let element = window.document.getElementById(id)
        if (!element) return false // 切换时直接break
        var option = {
          data: chartData,
          conEle:'#'+id,
          width: 530,
          height: 350,
          padding:2
        }
        var bubble = new Bubble(option)
        bubble.click = function (d) {
          // TODO:: 列表点击事件
        }
        bubble.mouseenter = function(d,node){
          var $con = $("#"+id);
          var rectBox = $con[0].getBoundingClientRect();
          d3.select(node).style("cursor","pointer");
          $con.append(createInfoTip(d));
          $(".node-info").css({
            left: d3.event.x+20-rectBox.left,
            top: d3.event.y+20-rectBox.top
          }).show();
        }
        bubble.mouseleave = function(d){
          $(".node-info").remove();
        }
        bubble.init()
      }
    }
  }
</script>
<style scoped>
 .echarts_empty{height: 250px;}
/*方案热点媒体*/
.fardmt .mod-list li,.rdzt .mod-list li{margin-bottom: 0;}
.fardmt .mod-list,.fardmt .echarts_empty,.rdzt .mod-list,.rdzt .echarts_empty{height: 396px;overflow: hidden;}
.fardmt .mod-list-title2{width: 120px;display: inline-block;}
.fardmt .mod-list-title2 img{margin-left: 5px;}
.el-progress{width: 65%;}
/*方案网媒热点主题*/
.rdzt .num{width:80px;text-align: left;height:20px;background:rgba(244,244,244,1);border-radius:10px;}
.rdzt .num var{margin:1px 10px 0 20px;display: inline-block;width: 14px;height: 16px; background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;}
.rdzt .num .ico1,.rdht .num .ico1{background-image:url(../../../../assets/images/analysis/ico1.png);}
.rdzt .num .ico2,.rdht .num .ico2{background-image:url(../../../../assets/images/analysis/ico2.png);}
.rdzt .num .ico3,.rdht .num .ico3{background-image:url(../../../../assets/images/analysis/ico3.png);}
.rdzt .num .ico4,.rdht .num .ico4{background-image:url(../../../../assets/images/analysis/ico4.png);}
.rdzt .num em{color:rgba(0,0,0,.7);line-height: 20px;display: inline-block;vertical-align: top;}
.rdzt .mod-list-title1{width:398px;}
.rdzt h3 .num{background: none;margin-top: 0;color: #000;line-height: 36px;border-radius: 0;height: 36px;}
/*方案网媒热点文章*/
.rdwz .mod-list-title1{width:320px;}
.rdwz h3 .w-400{float: left;}
.rdwz .mod-list p{line-height: 22px;height: 44px;width:430px;float: left;padding-right:20px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;font-size: 12px;}
.lh-36 li{height: auto;margin-bottom: 10px;}
.rdwz .el-progress{width: 15%;}
.h-350,.echarts_empty.h-350{height: 350px;}
/*方案微博热点用户*/
.user_list li{float: left;border-right: 1px solid rgba(191,191,191,.4);margin:20px 11px 0;}
.user_list li:nth-child(5n),.user_list li:last-child{border: none;}
.user_list li .tx {width:80px;position: relative;margin-top: 10px;}
.user_list .img{width: 80px;height: 80px;border-radius: 50%;cursor: pointer;}
.user_list .tx .ico{width:16px;height:16px;z-index: 10;position: absolute;bottom:10px;right:10px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.user_list .tx .num{width:55px;height:52px;z-index: 10;position: absolute;top:0;left:0; background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.user_list .tx .top1{background-image: url(../../../../assets/images/analysis/top1.png);}
.user_list .tx .top2{background-image: url(../../../../assets/images/analysis/top2.png);}
.user_list .tx .top3{background-image: url(../../../../assets/images/analysis/top3.png);}
.user_list .con{width:100px;padding: 0 10px;font-size: 12px;line-height: 20px;}
.user_list .con p{height: 20px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.user_list .con a{font-weight:600;color: #3F90F7;}
.user_list .con em{color: #000;}
/*热门传播URL链接*/
.url_lj .mod-list-title1{width:48%;}
.url_lj .el-progress{width:30%;}
.url_lj .mod-list .tag.rdzs{width:15%;}
/*方案微博热点文章*/
.rdwb .rdwz li{height: auto;margin-bottom: 10px;}
.rdwb .rdwz .mod-list-title2{width:58%;padding-right: 10px;display: -webkit-box !important;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;white-space:normal;line-height: 22px;height: 44px;margin-top: 5px;}
.rdwb .rdwz .mod-list a.source{width: 120px;float: left;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.rdwb .rdwz .mod-list .ico{width:16px;height:16px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;float: left;overflow: hidden;margin:10px 5px 0 0;display: inline;}
.rdwb .rdwz .mod-list .rdzs{width:230px;text-align: left;}
.rdwb .rdwz .mod-list .xxly{width:120px;text-align: left;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.h-350,.echarts_empty.h-350{height: 350px;}
.rdwz .mod-list li{height: auto;}
.comment_article .mod-list-title1{width: 72%;}
.comment_article .mod-list .tag.rdzs{width:16%;}
</style>
